<template>
	<div class="ztnrbg">
		<div class="index_mv ztnr">
			<leftMeun :name='name' :menuid='id'></leftMeun>
			<div class="right_nr">
				<div class="list category_news_article" style="border: 0; margin: 0;">
					<rightMeun :name='name' :menuid='id'></rightMeun>
					<div class="read_articles" v-if="list.length">
						<div class="newsList" style="flex: 1; width: 100%;">
							<news v-for="(li,index) in list" :key='index' :name='name' :sx='sx' :index='indexs' :id='id' :msg="li" types="bggk" />
						</div>
						
					</div>
					<div v-else class="read_articles">
						探索中
					</div>
					 
					<div class="pages" style="margin-top:50px;">
						<Page v-if="count>10" :total="count" @on-change='chan' />
						<!-- <Page total="100" @on-change='chan' /> -->
					</div>
				</div>
			</div>
            	<newRightMeun></newRightMeun>
		</div>
	</div>
</template>

<script>
	import news from '@/components/threeList.vue'
	import leftMeun from '@/components/leftMeun.vue'
	import rightMeun from '@/components/rightMeun.vue'
    import newRightMeun from '@/components/newRightMeun.vue'
	export default {
		components: {
			news,
			leftMeun,
			rightMeun,
            newRightMeun
		},
		data() {
			return {
				id:this.$route.query.pid,
				indexs:this.$route.query.index,
				sx:this.$route.query.sx,
				list: [],
				page: 1,
				count: 0,
				name:'',
				fid:this.$route.params.newsid
			}
		},
		watch: {
			$route: 'types'
		},

		methods: {
			chan(e) {
				var that = this
				that.page = e
				that.lists()
			},
			lists() {
				var that = this,fid
				if(that.$route.query.type=='news'){
					fid=that.$route.query.catid
				}else{
					fid=that.$route.params.newsid
				}
				that.$api.news({
					page: that.page,
					row: 10,
					fid: fid,
				}).then(res => {
					that.$emit('getLoad', true);
					that.list = res.data

				}).catch((error) => {
					// error
					console.log(error)
				})
			},
			types() {
				var that = this,fid
				that.id=that.$route.query.pid
				that.index=that.$route.query.index
				that.sx=that.$route.query.sx
				that.fid=that.$route.params.newsid
				// that.name=that.$route.query.sx,
				that.$api.menus({
					fid: 0
				}).then(res => {
					that.name= res.data[that.$route.query.index].title
				}).catch((error) => {
					// error
					console.log(error)
				})
				
				if(that.$route.query.type=='news'){
					fid=that.$route.query.catid
				}else{
					fid=that.$route.params.newsid
				}
				that.$api.news({
					page: that.page,
					row: 10,
					fid: fid,
					}).then(res => {
					that.$emit('getLoad', true);
					that.list = res.data
					that.count = res.count
					// that.list=that.list.concat(res.data)
					// that.list=that.list.concat(res.data)
					// that.list=that.list.concat(res.data)
				}).catch((error) => {
					// error
					console.log(error)
				})
			}
		},
		mounted() {

			this.types()
		}
	}
</script>

<style >
</style>
