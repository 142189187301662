<template>
	<div class="threeList">
		<div class="lis">
			<a v-if="msg.jump == 'url'" :href="msg.url" :title="msg.title" target="_blank">
				<ScaleDiv :scale="268 / 398" w="100%" :img="GS(msg.thumb)">
				</ScaleDiv>
				<p>{{ msg.title }}</p>
			</a>
			<router-link :to="'/details/' + tzfs + '/' + msg.id + '?type=' + types" :title="msg.title">
				<!-- <ScaleDiv :scale="268/398" w="100%" :img="GS(msg.thumb)">
				</ScaleDiv> -->
				<img :src="msg.thumb" alt="" />
				<!-- <p>{{msg.title}}</p> -->
				<div class="title">领导</div>
			</router-link>
		</div>
	</div>
</template>

<script>
import ScaleDiv from './ScaleDiv.vue'
export default {
	components: {
		ScaleDiv
	},
	name: 'tptList',
	props: {
		'msg': {
			type: Object,
			default() {
				return [];
			}
		},
		'id': {
			type: String,
			default: ""
		},
		'sx': {
			type: String,
			default: ""
		},
		'index': {
			type: String,
			default: ""
		},
		'types': {
			type: String,
			default: ""
		},
		'tzfs': {
			type: String,
			default: "cont"
		}
	},
	created() {
		// console.log(this.msg, 12)
	}
}
</script>

<style>
.threeList {
	width: 30%;
	height: auto;
}

.threeList img {
	width: 100%;
	height: 230px;
}

.threeList .title {
	text-align: center;
	color: #666;
	font-size: 16px;
	padding: 12px;
}
</style>
